import './App.css';
import React, {useState} from "react"

function App() {
  let [price, setPrice]= useState(0)
  
  const calculate= ()=>{
    let cost=+document.querySelector('#cost').value
    let setTax=+document.querySelector('#tax').value/100 
    let setProfit=+document.querySelector('#profit').value/100 
    setPrice(price+cost+cost*setTax+cost*setProfit)
    return setPrice
  }

  return (
    <div className="App App-header">
      <h1>Sample Retail Price Calculator</h1>
      <div className="Input-fields">
        <input type="number" id="cost" placeholder="Product Cost" />
        <input type="number" id="tax" placeholder="Taxes" />
        <input type="number" id="profit" placeholder="Profit Margin" />
        <button type="submit" onClick={calculate}>Calculate</button>
      </div>
      <h2>{price}</h2>
    </div>
  );
}

export default App;
